.btn-basic-deactivated{
  font-family: 'GothamXNarrow';
  font-size: 14px;
  border-style: none;
  padding: 13px;
  border-radius: 4px;
  font-weight: bold;
  color: rgba(150,150,150,0.21);
  width: 100%;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0,0,0,0.3);
  }