.leaderboard-space {
  background-color: white;
  min-height: 100vh;
  width: 100%;
  padding-top: 110px;
}

.leaderboard-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
