.isEven {
  background-color: rgba(0, 94, 52, 0.08);
  width: 100%;
  height: 75px;
}

.isUneven {
}

.leaderboard-dropdown {
  width: 70%;
  padding: 16px;
}
.leaderboard-dropdown .Dropdown-root .is-open {
  border: 0px solid #007540 !important;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.leaderboard-dropdown .Dropdown-menu {
  border: 0px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}
.leaderboard-dropdown .Dropdown-root {
  border: 3px;
  font-weight: bold;
}
.leaderboard-dropdown .Dropdown-control {
  border: none;
  font-weight: bold;
}
.leaderboard-dropdown .Dropdown-control:hover {
  box-shadow: none;
}
.leaderboard-dropdown .Dropdown-arrow-wrapper .Dropdown-arrow {
  border-color: black transparent transparent;
}
.leaderboard-dropdown .Dropdown-control {
  border: none;
  font-weight: bold;
  width: 90%;
}
.leaderboard-dropdown .Dropdown-arrow-wrapper .Dropdown-arrow {
  border-color: none !important;
}

.leaderboard-entry {
  width: 100%;
  height: 50px;
  display: flex;
}

.user-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  padding-left: 16px;
}

.leaderboard-user {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.leaderboard-user-img {
  width: 48px;
  height: 48px;
  border-radius: 40px;
  margin-left: 25px;
  margin-right: 5px;
  object-fit: cover;
}

.leaderboard-user-name {
  margin-left: 10px;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #000000;
  flex-grow: 1;
}

.leaderboard-medal {
  margin-right: 25px;
}
.leaderboard-index {
  min-width: 20px;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.leaderboard-user-score {
  font-family: "GothamXNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
