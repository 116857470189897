.username-container {
  width: 100%;
  max-width: 625px;
  margin: auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.username-input-container{
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.username-input {
  padding: 18px;
  width: 80%;
  border-radius: 2px;
  background-color: #005e34;
  border-color: #009150;
  border-width: 1px;
  text-align: center;

  opacity: 1;
  font-family: "GothamXNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.5; /* Firefox */
}

.username-agb-container {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 50px;
}
.username-agb-text {
  font-family: 'GothamNarrow';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #ffffff;
  margin-left: 7px;
}

.username-btn-container {
  width: 90%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-left: 24px;
  margin-right: 24px;
}
