.transcation-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
}

.transaction-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
}

.transaction-date {
  font-family: "GothamXNarrow";
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #969696;
  margin-top: 4px;
  display: flex;
}

.transcation-description {
  flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 16px;
}

.transaction-points-positiv {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: right;
  color: #007540;
}
.transaction-points-negativ {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
}

.transaction-descrtiption-text {
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.instantprice_pickedup{
  font-family: 'GothamNarrow';
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  background-color: #007540;
  padding: 2px;
  margin-left: 5px;
}