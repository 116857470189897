.overlay {
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  border-style: none;
  box-shadow: none;
}
rodal-dialog {
  background-color: #007540;
}
