.transaction-error-page {
  width: 100%;
  height: calc(100vh - 250px);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  z-index: 2;
}

.transaction-error-img {
  width: 70px;
  margin-bottom: 30px;
}
.transaction-error-title {
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #969696;
  margin-bottom: 40px;
}
.transaction-error-btn-container {
  padding-left: 60px;
  padding-right: 60px;
}
