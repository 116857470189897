.profile-container {
  margin-top: 70px;
  margin-bottom: 20px;
}

.profil-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  padding-bottom: 26px;
  padding-top: 30px;
}

.profil-header-image {
  width: 72px;
  height: 72px;
  border-radius: 50px;
  object-fit: cover;
}
.profil-header-image-btn-container {
  position: relative;

}
.profil-header-image-btn {
  width: 23px;
  height: 23px;
  border-radius: 15px;
  position: absolute;
  right: 0px;
  bottom: 4px;
  cursor: pointer;
}

.profil-header-text-block {
  height: 72px;
  display: flex;
  justify-content: center;
  margin-left: 17px;
  display: flex;
  flex-direction: column;
}

.profile-username {
  font-family: "GothamXNarrow";
  font-size: 18px;
  letter-spacing: 0;
  font-weight: bold;
  color: white;
}

.profile-address {
  margin-top: 8px;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.profil-link {
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  font-family: "GothamXNarrow";
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.profil-link:hover {
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #CCE3D8;
  font-family: "GothamXNarrow";
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.profil-link-icon {
  padding-right: 16px;
}


.profile-link-agb {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 25px;
  cursor: pointer;
}

.profile-link-agb:hover {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #CCE3D8;
  margin-bottom: 25px;
  cursor: pointer;
}
.profil-links {
  padding: 18px;
  padding-top: 0px;
}
.profil-spacer {
  border-style: solid;
  border-width: 0.5px;
  border-color: #006638;
  width: 100%;
}
.rotate90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
 }
 .rotate180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
 }
 .rotate270 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
 }
