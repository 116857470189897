.bubbles-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
}
.bubble-inactive {
  margin: 5px;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  opacity: 0.5;
  background-color: #ffffff;
}
.bubble-active {
  margin: 5px;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: #ffffff;
}
