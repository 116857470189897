* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #007540;
  overflow-x: hidden;
}

@-moz-document url-prefix() {
  .element {
    margin-right: -15px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "GothamNarrow";
  font-weight: 800;
  src: url("./assets/fonts/gothamnarrow/GothamNarrowBold.otf");
  /* IE9 Compat Modes */
}

@font-face {
  font-family: "GothamNarrow";
  font-style: normal;
  src: url("./assets/fonts/gothamnarrow/GothamNarrow-Light.otf");
  /* IE9 Compat Modes */
}

@font-face {
  font-family: "GothamXNarrow";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/gothamnarrow/Gotham\ XNarrow\ Medium.otf");
  /* IE9 Compat Modes */
}

@font-face {
  font-family: "GothamXNarrow";
  font-weight: 800;
  src: url("./assets/fonts/gothamnarrow/GothamNarrowBold.otf");
  /* IE9 Compat Modes */
}

.slick-dots li button:before {
  color: #fff !important;
}

.app-main-container {
  min-width: 100vw;
  min-height: 100vh;
  background-color: #007540;
}

.app-inner-container {
  max-width: 625px;
  margin: auto;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.desktop-friendly-row {
  max-width: 625px;
  margin: auto;
}

.slideup-modal {
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-container-big {
  width: 100%;
  height: 100%;
}

.modal-container-big-container {
  width: 100%;
}

.login-image-container-desktop {
  display: none;
}

.login-image-container {
  display: block;
  margin-top: 70px;
}

.code-input-helper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #007540;
  border-radius: 8px;
}

.swal2-modal {
  background-color: #007540 !important;
  color: #fff !important;
}

.swal2-content {
  color: #fff !important;
}

.swal2-icon.swal2-error {
  border-color: #fff !important;
}

.swal2-x-mark-line-left {
  background-color: #fff !important;
}

.swal2-x-mark-line-right {
  background-color: #fff !important;
}

.swal2-styled:focus {
  box-shadow: none !important;
}

.swal2-actions button {
  color: #000 !important;
  font-weight: bold;
}

.hide-on-mobile {
  display: none !important;
}

@media only screen and (max-width: 649px) {
  .desktop-header {
    display: none !important;
  }
  .slick-arrow {
    display: none !important;
  }
  .slick-list {
    padding-left: 18px;
  }
}

@media only screen and (min-width: 650px) {
  .hide-on-mobile {
    display: flex !important;
  }  
  .code-input-helper {
    height: initial;
    overflow: initial;
  }
  .main-navbar {
    display: none !important;
  }
  .slick-list {
    padding-left: 0px;
  }
  .shop-slider-container {
    margin-left: 18px;
    margin-right: 18px;
  }
  .modal-container-big-container {
    max-width: 550px;
  }
  .cardhuge-title {
    max-width: 70% !important;
  }
  .cardhuge-container {
    height: 500px !important;
  }
  .header-container {
    display: none !important;
  }
  .login-image-container {
    display: none !important;
  }
  .login-image-container-desktop {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: calc(100vh - 70px);
  }
  .login-image-container-desktop .content-container {
    position: absolute;
    top: 70px;
    left: 0px;
    width: 100vw;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: row;
  }
  .shop-card-item-container {
    width: calc(33% - 16px)!important;
    padding: 8px!important;
  }
}

@media print {
  .main-navbar {
    display: none !important;
  }
  .header {
    display: none !important;
  }
}

.gifgif {
  margin: auto;
  padding-top: 40px;
}

.gifgif img {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}

.grecaptcha-badge {
  display: none !important;
}