.transaction-space {
  background-color: white;
  min-height: calc(100vh - 70px);
  width: 100%;
  padding-top: 20px;
  height: 100%;
  padding-top: 110px; 
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;

  overflow: hidden;
}



.transaction-dropdown .Dropdown-control {
  border: 1px #fff solid;
}

.transaction-dropdown .Dropdown-placeholder {
  font-weight: bold;
}

.transactions-title-container {
  margin-bottom: 18px;
  margin-top: 30px;
}
.transaction-dropdown {
  width: calc(100% - 32px);
  padding: 16px;
}
.transaction-dropdown .Dropdown-root .is-open {
  border: 0px solid #007540 !important;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.transaction-dropdown .Dropdown-menu {
  border: 0px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}
.transaction-dropdown .Dropdown-root {
  border: 3px;
  font-weight: bold;
}
.transaction-dropdown .Dropdown-control {
  border: none;
  font-weight: bold;
  margin-left: 0px;
  padding-left: 0px;
}
.transaction-dropdown .Dropdown-control:hover {
  box-shadow: none;
  margin-left: 0px;
  padding-left: 0px;
}
.transaction-dropdown .Dropdown-arrow-wrapper .Dropdown-arrow {
  border-color: black transparent transparent;
}
.transaction-dropdown .Dropdown-control {
  border: none;
  font-weight: bold;
  width: 100%;
}
.transaction-dropdown .Dropdown-arrow-wrapper .Dropdown-arrow {
  border-color: none !important;
}

.transactions-title {
  font-family: "GothamXNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
  margin-left: 16px;
}
