.inputcomponent-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-color: #009150;
  border-width: 3px;
  border-style: solid;
  background-color: #005e34;
  margin: 6px;
}

.inputcomponent-row-red {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-color: #ff4d4d;
  border-width: 3px;
  border-style: solid;
  background-color: #005e34;
  margin: 6px;
}

.inputcomponent-row-green {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-color: #00b061;
  border-width: 3px;
  border-style: solid;
  background-color: #005e34;
  margin: 6px;
}

.inputcomponent-btn {
  margin: 6px;
  margin-top: 20px;
  background-color: #ffd42e;
  border-radius: 5px;
  padding: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.inputcomponent-btn-text {
  margin: 15px;
  font-family: "GothamXNarrow";
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0f0f0f;
}

.errormessage-container {
  border-radius: 5px;
  display: flex;
  margin: 6px;
  padding: 7px;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 30px;
  cursor: pointer;
}

.errormessage-img {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.errormessage-text {
  opacity: 0.9;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-left: 8px;
}

.errormessage-container-background {
  height: 100%;
  width: 100%;
  background-color: #000000;
  opacity: 0.2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 5px;
}

.error_out_of_range_text {
  margin-top: 18px;
  margin-bottom: 8px;
  font-family: "GothamNarrow";
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.input-component-oval {
  display: flex;
  justify-content: center;
  align-content: center;
}

.input-component-oval-text {
  font-family: "GothamNarrow";
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
  width: 40px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-top: 20px;
}
