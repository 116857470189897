.loading-image {
  width: 80%;
}

@media screen and (min-width: 600px) {
  .loading-image {
    max-width: 100px;
    margin: auto;
  }
}
