.shop-detail-confirmation-container {
  background-color: white;
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding-top: 110px;
  padding-bottom: 40px;
}

.shop-detail-confirmation-code {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 37px;
}

.shop-detail-confirmation-code-text {
  padding: 14px;
  width: 40%;
  text-align: center;
  font-family: "GothamNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.1px;
  text-align: center;
  color: #1a1a1a;
  margin: 14px;
  border-color: #005e34;
  border-width: 2px;
  border-style: solid;
}

.shop-detail-confirmation-title {
  font-family: "GothamXNarrow";
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #007540;
  margin-top: 35px;
  margin-left: 18px;
  margin-right: 18px;
}

.shop-detail-confirmation-subtitle {
  font-family: "GothamNarrow";
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.1px;
  color: #1a1a1a;
  margin-left: 18px;
  margin-right: 18px;
  margin-top: 8px;
  margin-bottom: 10px;
}
.shop-detail-confirmation-points {
  position: absolute;
  top: 114px;
  right: 19px;
  background-color: #ffd42e;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.shop-detail-confirmation-points-text {
  font-family: "GothamXNarrow";
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.shop-detail-confirmation-cattitle {
  font-family: "GothamXNarrow";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 28px;
}

.shop-detail-confirmation-text-bold {
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: 0.1px;
  color: #1a1a1a;
  margin-left: 20px;
  margin-right: 20px;
}

.shop-detail-confirmation-text {
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: 0.1px;
  color: #1a1a1a;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 75px;
}

.barcode-container {
  display: flex;
  justify-content: center;
}
