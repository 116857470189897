.events-overview-container {
  width: 100%;
  padding-top: 70px;
}

.no-events-at-the-moment {
  padding: 18px;
  font-family: 'GothamNarrow';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  opacity: 0.7;
}