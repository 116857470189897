.event-details-container {
    background-color: #fff;
    width: 100%;
    min-height: 100vh;
    position: relative;
}

.event-not-yet-started {
    font-family: GothamNarrow;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000000;
    font-weight: bold;
}

.event-details-price {
    width: 68px !important;
    height: 68px !important;
    position: absolute;
    top: 18px;
    right: 18px;
}

.event-details-container img {
    width: 100%;
    height: 300px;
    max-height: 70vw;
    object-fit: cover;
}

.event-details-price-img-container {
    width: 100%;
    position: relative;
    padding-top: 90%;
}

.event-details-price-img-container img {
    max-height: none !important;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.event-details-container-header-image {
    width: 100%;
    height: auto;
    max-height: 70vw;
    object-fit: cover;
}

.event-details-container-content {
    padding: 20px 30px;
    padding-top: 30px;
    padding-bottom: 100px;
}

.event-details-container-title {
    font-family: GothamXNarrow;
    font-size: 36px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.11;
    letter-spacing: 0.2px;
    color: #1e1e1e;
}

.event-detail-text {
    font-weight: bold;
}

.event-detail-text-bold {
    font-weight: bold;
}

.event-detail-text-color {
    color: #007540 !important;
}

.event-details-container-wrapper {
    margin-top: 37px;
    margin-bottom: 30px;
}

.event-details-container-description {
    font-family: GothamNarrow;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000000;
    padding-top: 9px;
    padding-bottom: 9px;
    margin-bottom: 24px;
}

.event-details-container-description span {
    font-family: GothamNarrow;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000000;
}

.mb-60 {
    margin-bottom: 60px;
}