.question-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  padding-top: 70px;
}
.question-header-title {
  font-family: "GothamXNarrow";
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-left: 16px;
  margin-right: 16px;
}

.question-header-subtitle {
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #ffffff;
}

.question-header-image {
  width: 60px;
  height: 60px;
}
.question-header-text {
  font-family: "GothamXNarrow";
  font-size: 38px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.76;
  letter-spacing: normal;
  color: #ffffff;
}

.question-header-answered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.question-header-answered-subtext {
  margin-top: 20px;
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.points-plus {
  font-family: "GothamXNarrow";
  font-size: 23px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
}
