.collectpoints-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.collecpoints-banner {
  width: 60%;
  margin-top: 20px;
}
