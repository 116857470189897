.shop-card-item {
  background-color: white;
  border-radius: 5px;
  width: 100%;
  padding-bottom: 133%;
  position: relative;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  overflow: hidden;
  cursor: pointer;
}
::-webkit-scrollbar {
  display: none;
}

.shop-card-item-top-right {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  width: 50px;
  height: 50px;
  background-color: #fbd42e;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 24px;
  font-family: "GothamXNarrow";
  text-align: center;
}

.shop-card-item-title {
  position: absolute;
  bottom: 0;
  left: 0;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000000;
  margin: 20px;
}
.shop-card-backgrounditem {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.shop-card-top-right {
  position: absolute;
  top: 6px;
  right: 6px;
  background: #ffd42e;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
