.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #008a4c;
  z-index: 99;
}

.main-header-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50px;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  width: 100%;
  height: 100%;
}

.header-solo {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-solo img {
  cursor: pointer;
}

.header-title {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  font-family: "GothamXNarrow";
}

.header-score {
  font-size: 14px;
  color: #1a1a1a;
  font-family: "GothamXNarrow";
}

.desktop-header {
  width: 100%;
  height: 50px;
}

.desktop-header .inner-container {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 950px;
  margin: auto;
}

.inner-container {
  cursor: pointer;
}

.desktop-header-inner-center {
  justify-content: center !important;
}

.desktop-header .nav-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;
  justify-content: space-evenly;
}

.desktop-header .nav-items-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
}

.nav-items-item-active {
  color: #00542e !important;
}

.desktop-header .nav-items-item img {
  width: auto;
  height: 20px;
  margin-right: 10px;
}

.desktop-header .nav-items-item .nav-items-item-text {
  font-size: 16px;
  font-weight: bold;
  font-family: 'GothamXNarrow';
}

.desktop-header .nav-items-item:hover {
  opacity: 0.8;
}

.header-collect-points {
  background-color: #00b061;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
}

.header-collect-points img {
  height: 12px;
  width: auto;
  display: block;
  margin: 0px;
  margin-right: 0px !important;
}