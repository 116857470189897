.shop-container {
  margin-top: 70px;
}

.shop-container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50;
  background-color: #007540;
  height: 100%;
  width: 100%;
  z-index: 99;
}


.shop-title-container {
  padding: 36px;
  padding-top: 30px;
}

.shop-title {
  padding-top: 30px;
  font-family: "GothamXNarrow";
  font-size: 38px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.shop-subtitle {
  margin-top: 5px;
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.shop-section-title {
  font-family: "GothamXNarrow";
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #ffffff;
}

.shop-section-subtitle {
  margin-top: 12px;
  font-family: "GothamXNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffd42e;

  cursor: pointer;
}

.shop-slider-container {
  margin-top: 16px;
}

.shop-item-overlapping-card {
  padding-right: 10px;
  width: calc(100% - 10px) !important;
}

.shop-section-sidescroll {
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.shop-section-sidescroll .shop-card-item {
  margin-right: 8px;
}

.shop-section-sidescroll .shop-card-item:first-of-type {
  margin-left: 16px;
}

.shop-divider {
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: #006638;
  margin: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.carousel .slide img {
  height: 300px;
  object-fit: cover;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
}