* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #007540;
  overflow-x: hidden;
}

@-moz-document url-prefix() {
  .element {
    margin-right: -15px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "GothamNarrow";
  font-weight: 800;
  src: url(/static/media/GothamNarrowBold.a43840f5.otf);
  /* IE9 Compat Modes */
}

@font-face {
  font-family: "GothamNarrow";
  font-style: normal;
  src: url(/static/media/GothamNarrow-Light.7c9a1387.otf);
  /* IE9 Compat Modes */
}

@font-face {
  font-family: "GothamXNarrow";
  font-style: normal;
  font-weight: 400;
  src: url("/static/media/Gotham XNarrow Medium.6e476303.otf");
  /* IE9 Compat Modes */
}

@font-face {
  font-family: "GothamXNarrow";
  font-weight: 800;
  src: url(/static/media/GothamNarrowBold.a43840f5.otf);
  /* IE9 Compat Modes */
}

.slick-dots li button:before {
  color: #fff !important;
}

.app-main-container {
  min-width: 100vw;
  min-height: 100vh;
  background-color: #007540;
}

.app-inner-container {
  max-width: 625px;
  margin: auto;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.desktop-friendly-row {
  max-width: 625px;
  margin: auto;
}

.slideup-modal {
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-container-big {
  width: 100%;
  height: 100%;
}

.modal-container-big-container {
  width: 100%;
}

.login-image-container-desktop {
  display: none;
}

.login-image-container {
  display: block;
  margin-top: 70px;
}

.code-input-helper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #007540;
  border-radius: 8px;
}

.swal2-modal {
  background-color: #007540 !important;
  color: #fff !important;
}

.swal2-content {
  color: #fff !important;
}

.swal2-icon.swal2-error {
  border-color: #fff !important;
}

.swal2-x-mark-line-left {
  background-color: #fff !important;
}

.swal2-x-mark-line-right {
  background-color: #fff !important;
}

.swal2-styled:focus {
  box-shadow: none !important;
}

.swal2-actions button {
  color: #000 !important;
  font-weight: bold;
}

.hide-on-mobile {
  display: none !important;
}

@media only screen and (max-width: 649px) {
  .desktop-header {
    display: none !important;
  }
  .slick-arrow {
    display: none !important;
  }
  .slick-list {
    padding-left: 18px;
  }
}

@media only screen and (min-width: 650px) {
  .hide-on-mobile {
    display: -webkit-flex !important;
    display: flex !important;
  }  
  .code-input-helper {
    height: auto;
    height: initial;
    overflow: visible;
    overflow: initial;
  }
  .main-navbar {
    display: none !important;
  }
  .slick-list {
    padding-left: 0px;
  }
  .shop-slider-container {
    margin-left: 18px;
    margin-right: 18px;
  }
  .modal-container-big-container {
    max-width: 550px;
  }
  .cardhuge-title {
    max-width: 70% !important;
  }
  .cardhuge-container {
    height: 500px !important;
  }
  .header-container {
    display: none !important;
  }
  .login-image-container {
    display: none !important;
  }
  .login-image-container-desktop {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100vw;
    height: calc(100vh - 70px);
  }
  .login-image-container-desktop .content-container {
    position: absolute;
    top: 70px;
    left: 0px;
    width: 100vw;
    height: calc(100vh - 70px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .shop-card-item-container {
    width: calc(33% - 16px)!important;
    padding: 8px!important;
  }
}

@media print {
  .main-navbar {
    display: none !important;
  }
  .header {
    display: none !important;
  }
}

.gifgif {
  margin: auto;
  padding-top: 40px;
}

.gifgif img {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}

.grecaptcha-badge {
  display: none !important;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.content {
  background-color: #007540;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden; }

.content-top {
  padding: 20px;
  color: white;
  font-size: 24px;
  position: fixed;
  top: 0;
  height: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  left: 0;
  right: 0;
  background-color: #007540; }

.link-u {
  cursor: pointer; }

.content-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.how-it-works-container {
  margin-top: 70px; }

.login-image-container .fake-spacer {
  width: 100vw;
  height: 100vh; }

.login-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 50px;
  background-color: #008a4c;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 99; }

.content-center .title {
  font-family: "GothamXNarrow";
  font-size: 34px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.content-center .subtitle {
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.login-image-container {
  position: relative; }

.logo-container {
  width: 100%;
  object-fit: contain; }

.logo-container-desktop {
  width: 100%; }

.login-body-desktop {
  background-color: #1f6131;
  width: 460px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 40px; }

.logo-container-desktop img {
  width: 100%;
  height: calc(100vh - 70px);
  object-fit: cover; }

.logo {
  object-fit: cover;
  width: 100%; }

.btn-facebooklogin {
  width: 100%;
  background-color: #3c5a99;
  color: white;
  font-size: 20px;
  border-style: none;
  padding: 15px;
  margin-bottom: 12px;
  border-radius: 4px;
  font-weight: bold;
  font-family: "GothamXNarrow";
  cursor: pointer; }

.btn-mobileemail {
  width: 100%;
  background-color: white;
  color: black;
  font-size: 20px;
  border-style: none;
  padding: 13px;
  margin-bottom: 12px;
  border-radius: 4px;
  font-weight: bold;
  font-family: "GothamXNarrow";
  cursor: pointer; }

.content-agb {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 52px;
  margin-top: 60px; }

.content-agb-element {
  margin-right: 15px;
  margin-bottom: 15px;
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer; }

iframe {
  background-color: #fff; }

.btn-emaillogin {
  font-family: "GothamNarrow";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 20px; }

.btn-emaillogin a {
  font-family: "GothamNarrow";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.login-body {
  z-index: 1;
  height: calc(100vh - 120px);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: absolute;
  bottom: -20px;
  margin-top: 80px; }

.title {
  font-family: "GothamXNarrow";
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.login-body-desktop .title {
  margin-bottom: 14px; }

.subtitle {
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px; }

.detail-title {
  font-family: "GothamXNarrow";
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 100px; }

.bottom-btn-container {
  border-radius: 3px;
  background-color: #006638;
  margin: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }

.content-bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  padding-bottom: 20px; }

.bottom-btn-container-title {
  margin-top: 30px;
  margin-bottom: 40px;
  font-family: "GothamXNarrow";
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px; }

.link-u {
  text-decoration: underline; }

.main-login-page {
  padding-top: 70px; }
  .main-login-page .header {
    background-color: #008a4c;
    width: 100%;
    height: 70px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .main-login-page .header img {
    height: 26px;
    width: auto; }
  .main-login-page .info-block {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: calc(100vh - 70px); }
  .main-login-page .info-block .info-block-image {
    display: block;
    height: 100%;
    width: calc(100vw - 480px);
    object-fit: cover;
    background-position: center top;
    -webkit-flex-grow: 1;
            flex-grow: 1; }
  .main-login-page .info-block-info {
    width: 480px;
    background-color: #1f6131;
    padding-left: 60px;
    padding-right: 60px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .main-login-page .text-header {
    font-family: GothamXNarrow;
    font-size: 42px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff; }
  .main-login-page .text-body {
    font-family: GothamNarrow;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff; }
  .main-login-page .main-content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .main-login-page .stoerer {
    width: 198px;
    height: 198px;
    margin-top: 67px;
    margin-bottom: 40px; }
  .main-login-page h1 {
    font-family: "GothamXNarrow";
    font-size: 42px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    max-width: 625px; }
  .main-login-page .teaser {
    opacity: 0.8;
    font-family: "GothamNarrow";
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-top: 23px;
    max-width: 625px; }
  .main-login-page .sogehts {
    font-family: "GothamXNarrow";
    font-size: 26px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-top: 90px;
    margin-bottom: 60px; }
  .main-login-page .item-container {
    margin-bottom: 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    max-width: 730px; }
  .main-login-page .item-container-image {
    width: 290px;
    height: auto; }
  .main-login-page .item-container-info-title {
    font-family: "GothamXNarrow";
    font-size: 42px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff; }
  .main-login-page .item-container-info-text {
    opacity: 0.8;
    font-family: "GothamNarrow";
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    color: #ffffff; }
  .main-login-page .mt-15 {
    margin-top: 15px; }
  .main-login-page .ml-15 {
    margin-left: 44px; }
  .main-login-page .mr-15 {
    margin-right: 44px; }
  .main-login-page .mobile {
    display: none !important; }
  .main-login-page .desktop {
    display: block !important; }
  @media only screen and (max-width: 650px) {
    .main-login-page .info-block {
      -webkit-flex-direction: column;
              flex-direction: column;
      width: 100%;
      position: relative; }
    .main-login-page .info-block-info {
      position: absolute;
      bottom: 30px;
      left: 0px;
      right: 0px;
      background-color: transparent;
      width: calc(100% - 40px);
      padding-left: 20px;
      padding-right: 20px;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
    .main-login-page .info-block .info-block-image {
      width: 100%; }
    .main-login-page .mobile {
      display: block !important; }
    .main-login-page .desktop {
      display: none !important; }
    .main-login-page .ml-15 {
      margin-left: 0px; }
    .main-login-page .mr-15 {
      margin-right: 0px; }
    .main-login-page .item-container {
      -webkit-flex-direction: column;
              flex-direction: column; }
    .main-login-page .item-container-reverted-mobile {
      -webkit-flex-direction: column-reverse;
              flex-direction: column-reverse; }
    .main-login-page .item-container-image {
      margin-top: 44px; }
    .main-login-page .item-container-info {
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;
      width: calc(100% - 60px); }
    .main-login-page .teaser {
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;
      width: calc(100% - 60px); }
    .main-login-page h1 {
      text-align: center;
      padding-left: 30px;
      padding-right: 30px; }
    .main-login-page .item-container-image {
      width: 90%;
      height: auto; } }

.footer-links {
  margin-top: 100px;
  margin-bottom: 50px;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.footer-links .link {
  text-decoration: none;
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0px 20px; }

@media only screen and (max-width: 650px) {
  .footer-links {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-bottom: 60px; }
  .footer-links .link {
    padding-bottom: 20px;
    display: none; }
  .footer-links-always .link {
    display: block; } }

.fullscreen {
  width: 100%;
  height: 100%;
  background-color: #007540;
  position: relative;
  padding-top: 80px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #008a4c;
  z-index: 99;
}

.main-header-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  height: 50px;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  max-width: 500px;
  width: 100%;
  height: 100%;
}

.header-solo {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.header-solo img {
  cursor: pointer;
}

.header-title {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  font-family: "GothamXNarrow";
}

.header-score {
  font-size: 14px;
  color: #1a1a1a;
  font-family: "GothamXNarrow";
}

.desktop-header {
  width: 100%;
  height: 50px;
}

.desktop-header .inner-container {
  width: 100%;
  height: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  max-width: 950px;
  margin: auto;
}

.inner-container {
  cursor: pointer;
}

.desktop-header-inner-center {
  -webkit-justify-content: center !important;
          justify-content: center !important;
}

.desktop-header .nav-items {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  width: 70%;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.desktop-header .nav-items-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #ffffff;
  cursor: pointer;
}

.nav-items-item-active {
  color: #00542e !important;
}

.desktop-header .nav-items-item img {
  width: auto;
  height: 20px;
  margin-right: 10px;
}

.desktop-header .nav-items-item .nav-items-item-text {
  font-size: 16px;
  font-weight: bold;
  font-family: 'GothamXNarrow';
}

.desktop-header .nav-items-item:hover {
  opacity: 0.8;
}

.header-collect-points {
  background-color: #00b061;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 13px;
}

.header-collect-points img {
  height: 12px;
  width: auto;
  display: block;
  margin: 0px;
  margin-right: 0px !important;
}
.modal-content {
  width: 90%;
  border-radius: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  position: relative;
  height: 100%;
}

.modal-tutorial-button {
  margin-top: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.modal-skip-button {
  margin-top: 30px;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.modal-close-button {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 14px;
  left: 14px;
  cursor: pointer;
}

.modal-title {
  font-family: "GothamXNarrow";
  font-size: 38px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.modal-desc-text {
  margin: 24px;
  text-align: center;
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

@media screen and (min-width: 600px) {
  .modal-content {
    max-width: 500px;
    margin: auto;
  }
}

.ReactModal__Overlay {
  z-index: 199;
}

.ReactModal__Content {
  border: none !important;
}

.modal-center {
  z-index: 99;
  position: relative;
}
.onboard-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.onboard-center-img {
  width: 80%;
  height: 80%;
}
.onboard-footer {
  margin-top: 60px;
  width: 100%;
  margin-bottom: 20px;
  z-index: 2;
}
.onboard-footer-btn {
  padding-left: 20px;
  padding-right: 20px;
}

.btn-basic {
    font-size: 18px;
    border-style: none;
    padding: 13px;
    border-radius: 4px;
    font-weight: bold;
    font-family: 'GothamXNarrow';
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
    letter-spacing: 0.2px;
    color: #000000;
    width: 100%;
    cursor: pointer;
    text-transform: uppercase;
  }

  .btn-basic:hover {
    font-size: 18px;
    border-style: none;
    padding: 13px;
    border-radius: 4px;
    font-weight: bold;
    font-family: 'GothamXNarrow';
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
    letter-spacing: 0.2px;
    color: #000000;
    width: 100%;
    cursor: pointer;
    text-transform: uppercase;
}
.overlay {
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  border-style: none;
  box-shadow: none;
}
rodal-dialog {
  background-color: #007540;
}

.collectpoints-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.collecpoints-banner {
  width: 60%;
  margin-top: 20px;
}

.template-top {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  padding-bottom: 20px;
}

.template-bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.template-title-container {
}
.template-title {
  font-family: "GothamXNarrow";
  font-size: 38px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.template-subtitle {
  margin-top: 17px;
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.template-btn-container {
  margin-top: 10px;
  width: 100%;
  padding-left: 34px;
  padding-right: 34px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  bottom: 40px;
  max-width: 500px;
}
.templatetutorial-cancle {
  padding: 10px;
  padding-bottom: 0px;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.bubbles-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 22px;
}
.bubble-inactive {
  margin: 5px;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  opacity: 0.5;
  background-color: #ffffff;
}
.bubble-active {
  margin: 5px;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: #ffffff;
}

.collectpoints-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.collecpoints-banner {
  width: 60%;
  margin-top: 20px;
}

.collectpoints-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.collecpoints-banner {
  width: 60%;
  margin-top: 20px;
}

.collectpoints-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.collecpoints-banner {
  width: 60%;
  margin-top: 20px;
}

.collectpoints-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.collecpoints-banner {
  width: 60%;
  margin-top: 20px;
}

.cardhuge-container {
  width: 100%;
  height: 440px;
  margin-bottom: 10px;
}

.cardhuge-inner-container {
  border-radius: 5px;
  background-color: #004f2b;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.cardhuge-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.cardHuge-text-container {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
}

.cardhuge-title {
  font-family: "GothamXNarrow";
  font-size: 48px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #ffd42e;
}
.cardhuge-subtitle {
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 12px;
  margin-top: 10px;
}

.shop-card-full-with-item {
  width: 100%;
  border-radius: 5px;
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  padding-bottom: 44%;
}

.shop-card-full-with-item:hover {
  width: 100%;
  border-radius: 5px;
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  padding-bottom: 44%;
}

.shop-card-full-with-item--gradient {
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,0.9) 40%, rgba(255,255,255,0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.shop-card-full-with-item-title-container {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 20px;
}

.shop-card-full-with-item-title {
  font-family: "GothamNarrow";
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: black;
  margin-top: 10px;
}

.shop-card-full-with-item-subtitle {
  background-color: #ffd42e;
  padding: 5px;
  display: inline;
  font-family: "GothamNarrow";
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #000000;
}
.shop-card-item-full-with-top-right {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  width: 50px;
  height: 50px;
  background-color: #fbd42e;
  border-radius: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  font-size: 24px;
  font-family: "GothamXNarrow";
  text-align: center;
}

.shop-card-full-width-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: white;
  object-fit: cover;
}

.shop-card-full-width-background:hover {
  box-shadow: 0px 0px 3px 3px rgba(0,0,0,0.15);
}

.shop-card-item:hover {
  box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.25);
}
.card-container {
  width: 100%;
  height: 170px;
  background-color: white;
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;
}
.card-header {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 16px;
  left: 16px;
}
.card-header-title {
  font-family: "GothamXNarrow";
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffd42e;
  margin-left: 6px;
}
.card-body {
  padding: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  cursor: pointer;
  padding-bottom: 30px;
}
.card-body-text {
  font-family: "GothamXNarrow";
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #ffffff;
}
.card-body-image {
  height: 100%;
  position: absolute;
  right: 7px;
  top: 0;
  bottom: 10px;
}

.card-body-image .image {
  object-fit: contain;
}

.event-card {
  position: relative;
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
  padding-bottom: 44%;
  margin-bottom: 15px;
}

.event-card:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.event-card-big {
  background-color: white;
  height: 250px;
  margin: 5px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 5px;
}

.event-card-small {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0px;
  border-radius: 4px;
}

.event-card-small--gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 40%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.event-card-fullscree-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  display: block;
}

.event-card-image {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  display: block;
}

.event-location-dot {
  
  display: -webkit-flex;
  
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  top: 12px;
  left: 12px;
}

.event-location-dot-image {
  height: 20px;
  margin: 5px;
  margin-left: 0px;
}

.event-location-dot-title {
  font-family: "GothamXNarrow";
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
}

.event-location-points-fullscreen {
  position: absolute;
  top: 0px;
  right: 50px;
}

.event-location-points {
  position: absolute;
  top: 6px;
  right: 6px;
}

.event-location-points-image {
  width: 50px;
  height: 50px;
}

.event-location-points-fullscreen-image {
  width: 50px;
  height: 50px;
}

.event-card-fullscreen {
  position: absolute;
  left: 16px;
  bottom: 16px;
  right: 16px;
}

.event-card-fullscreen-text {
  width: 60%;
  font-family: "GothamNarrow";
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #000000;
  margin-top: 4px;
}

.event-card-text {
  width: 65%;
  font-family: "GothamNarrow";
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #000000;
  margin-top: 4px;
}

.event-card-text-white {
  color: #fff !important;
}

.event-card-tag-text {
  font-family: "GothamNarrow";
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #000000;
  display: inline;
  background-color: #ffd42e;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
}

.event-card-title {
  font-family: "GothamXNarrow";
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #ffffff;
}

.event-card-subtitle {
  margin-top: 12px;
  margin-bottom: 20px;
  font-family: "GothamXNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffd42e;
  cursor: pointer;
}
.navbar-container {
  background-color: rgba(204, 221, 213, 0.85);
  height: 56px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}


.navbar-item-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 20%;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.navbar-item-container-img {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 20%;
  min-height: 26px;
  cursor: pointer;
  margin-top: 5px;
}
.navbar-subtitle-aktiv {
  font-family: "GothamXNarrow";
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00542e;
  cursor: pointer;
}
.navbar-subtitle {
  font-family: "GothamXNarrow";
  font-size: 11px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 84, 46, 0.6);
  opacity: 60%;
  text-align: center;
  cursor: pointer;
}
.navbar-icon {
  height: 20px;
}

.navbar-points {
  width: 44px;
  height: 44px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #00b061;
  border-radius: 22px;
  position: absolute;
  top: -14px;
}

.main-navbar {
  position: fixed;
  bottom: -1px;
  left: 0px;
  right: 0px;
  height: 56px;
  background-color: rgb(199,219,209);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.pagetitleheader-container-green {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 34px;
  padding-top: 35px;
  padding-bottom: 28px;
}

.pagetitleheader-title-green {
  text-align: center;
  font-family: 'GothamXNarrow';
  font-size: 38px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.pagetitleheader-subtitle-green {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 5px;
  font-family: 'GothamNarrow';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  opacity: 0.7;
}

.events-overview-container {
  width: 100%;
  padding-top: 70px;
}

.no-events-at-the-moment {
  padding: 18px;
  font-family: 'GothamNarrow';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  opacity: 0.7;
}
.shop-container {
  margin-top: 70px;
}

.shop-container-loading {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50;
  background-color: #007540;
  height: 100%;
  width: 100%;
  z-index: 99;
}


.shop-title-container {
  padding: 36px;
  padding-top: 30px;
}

.shop-title {
  padding-top: 30px;
  font-family: "GothamXNarrow";
  font-size: 38px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.shop-subtitle {
  margin-top: 5px;
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.shop-section-title {
  font-family: "GothamXNarrow";
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #ffffff;
}

.shop-section-subtitle {
  margin-top: 12px;
  font-family: "GothamXNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffd42e;

  cursor: pointer;
}

.shop-slider-container {
  margin-top: 16px;
}

.shop-item-overlapping-card {
  padding-right: 10px;
  width: calc(100% - 10px) !important;
}

.shop-section-sidescroll {
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  padding-right: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.shop-section-sidescroll .shop-card-item {
  margin-right: 8px;
}

.shop-section-sidescroll .shop-card-item:first-of-type {
  margin-left: 16px;
}

.shop-divider {
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: #006638;
  margin: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.carousel .slide img {
  height: 300px;
  object-fit: cover;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
}
.loading-image {
  width: 80%;
}

@media screen and (min-width: 600px) {
  .loading-image {
    max-width: 100px;
    margin: auto;
  }
}

.shop-card-item {
  background-color: white;
  border-radius: 5px;
  width: 100%;
  padding-bottom: 133%;
  position: relative;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  overflow: hidden;
  cursor: pointer;
}
::-webkit-scrollbar {
  display: none;
}

.shop-card-item-top-right {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  width: 50px;
  height: 50px;
  background-color: #fbd42e;
  border-radius: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  font-size: 24px;
  font-family: "GothamXNarrow";
  text-align: center;
}

.shop-card-item-title {
  position: absolute;
  bottom: 0;
  left: 0;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #000000;
  margin: 20px;
}
.shop-card-backgrounditem {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.shop-card-top-right {
  position: absolute;
  top: 6px;
  right: 6px;
  background: #ffd42e;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.profile-container {
  margin-top: 70px;
  margin-bottom: 20px;
}

.profil-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 15px;
  padding-bottom: 26px;
  padding-top: 30px;
}

.profil-header-image {
  width: 72px;
  height: 72px;
  border-radius: 50px;
  object-fit: cover;
}
.profil-header-image-btn-container {
  position: relative;

}
.profil-header-image-btn {
  width: 23px;
  height: 23px;
  border-radius: 15px;
  position: absolute;
  right: 0px;
  bottom: 4px;
  cursor: pointer;
}

.profil-header-text-block {
  height: 72px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-left: 17px;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.profile-username {
  font-family: "GothamXNarrow";
  font-size: 18px;
  letter-spacing: 0;
  font-weight: bold;
  color: white;
}

.profile-address {
  margin-top: 8px;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.profil-link {
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  font-family: "GothamXNarrow";
  margin-bottom: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}
.profil-link:hover {
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #CCE3D8;
  font-family: "GothamXNarrow";
  margin-bottom: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}
.profil-link-icon {
  padding-right: 16px;
}


.profile-link-agb {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 25px;
  cursor: pointer;
}

.profile-link-agb:hover {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #CCE3D8;
  margin-bottom: 25px;
  cursor: pointer;
}
.profil-links {
  padding: 18px;
  padding-top: 0px;
}
.profil-spacer {
  border-style: solid;
  border-width: 0.5px;
  border-color: #006638;
  width: 100%;
}
.rotate90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
 }
 .rotate180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
 }
 .rotate270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
 }

.personal-space {
  background-color: white;
  min-height: calc(100vh - 70px);
  width: 100%;
  padding-top: 110px;
}

.personal-space-image-container {
  position: relative;
  margin-top: 45px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.personal-space-image {
  width: 95px;
  height: 95px;
  object-fit: cover;
}
.personal-space-image-btn {
  width: 29px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.personal-space-form {
  margin-top: 43px;
  width: 100%;
}

.personal-space-form-item {
  border-bottom: solid 0.5px #cbcbcb;
  margin-bottom: 29px;
}
.personal-space-form-item-edit {
  margin-bottom: 30px;
}
.personal-space-form-title {
  font-family: "GothamXNarrow";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
  margin-bottom: 2px;
}

.personal-space-form-input {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 22px;
  letter-spacing: normal;
  color: #000000;
  margin-top: 5px;
}

.personal-space-btn-edit {
  border-radius: 4px;
  background-color: #ffd42e;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 12px;
  margin-top: 48px;
  cursor: pointer;
}

.personal-space-btn-edit-text {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: 0.2px;
  color: #1a1a1a;
}

.personal-space-form-title {
  font-family: "GothamXNarrow";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
  margin-bottom: 2px;
}

.personal-space-form-input-edit {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  line-height: 22px;
  padding: 9px;
  padding-left: 10px;
  margin-top: 4px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #cbcbcb;
  width: 94%;
}

.personal-space-form-input-edit:focus {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  line-height: 22px;
  padding: 9px;
  padding-left: 10px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #007540;
  width: 94%;
  outline: none;
}

.field-container {
  position: relative;
  margin-top: 5px;
  width: 100%; }
  .field-container .textinput-gray {
    border: 1px solid #009150;
    box-sizing: border-box;
    border-radius: 5px;
    letter-spacing: -0.02em;
    width: calc(100% - 32px);
    padding: 16px;
    margin-bottom: 5px;
    width: 100%;
    height: 50px; }
  .field-container input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #005e34;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    color: #ffffff; }
  .field-container input:focus {
    border: 1px solid #ffd42e;
    outline: none; }
  .field-container .placeholdeText-error {
    color: #eb5757; }
  .field-container .placeholdeText {
    color: rgba(255, 255, 255, 0.6); }
  .field-container .textinput-error {
    border: 1px solid #eb5757;
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 16px;
    letter-spacing: -0.02em;
    width: calc(100% - 32px);
    padding: 16px;
    margin-bottom: 5px;
    width: 100%; }
  .field-container label {
    display: inline-block;
    position: absolute;
    left: 15px;
    top: 19px;
    transition: all 150ms ease-in;
    color: #bdbdbd; }
  .field-container label.field-active {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    font-size: 11px; }
  @media only screen and (max-width: 330px) {
    .field-container label.field-inactive {
      font-size: 12px; } }
  .field-container .field-group-active input {
    padding-top: 24px;
    padding-bottom: 8px; }
  .field-container .floating-label {
    -webkit-appearance: none !important; }

.Dropdown-root {
  position: relative;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  outline: none;
  padding: 11px 52px 11px 10px;
  transition: all 200ms ease;
}

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 18px;
  width: 0;
}
.is-open {
  border: 1px solid #007540;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.is-open .Dropdown-arrow {
  border-color: transparent transparent #007540;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  background-color: white;
  border: 1px solid #007540;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  /* margin-top: -1px; */
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: calc(100% + 2px);
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  padding: 1px;
  margin-top: 2px;
  margin-left: -1px;
  border-top: 0px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  border-color: #007540;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.leaderboard-space {
  background-color: white;
  min-height: 100vh;
  width: 100%;
  padding-top: 110px;
}

.leaderboard-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.isEven {
  background-color: rgba(0, 94, 52, 0.08);
  width: 100%;
  height: 75px;
}

.isUneven {
}

.leaderboard-dropdown {
  width: 70%;
  padding: 16px;
}
.leaderboard-dropdown .Dropdown-root .is-open {
  border: 0px solid #007540 !important;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.leaderboard-dropdown .Dropdown-menu {
  border: 0px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}
.leaderboard-dropdown .Dropdown-root {
  border: 3px;
  font-weight: bold;
}
.leaderboard-dropdown .Dropdown-control {
  border: none;
  font-weight: bold;
}
.leaderboard-dropdown .Dropdown-control:hover {
  box-shadow: none;
}
.leaderboard-dropdown .Dropdown-arrow-wrapper .Dropdown-arrow {
  border-color: black transparent transparent;
}
.leaderboard-dropdown .Dropdown-control {
  border: none;
  font-weight: bold;
  width: 90%;
}
.leaderboard-dropdown .Dropdown-arrow-wrapper .Dropdown-arrow {
  border-color: none !important;
}

.leaderboard-entry {
  width: 100%;
  height: 50px;
  display: -webkit-flex;
  display: flex;
}

.user-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 14px;
  padding-left: 16px;
}

.leaderboard-user {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.leaderboard-user-img {
  width: 48px;
  height: 48px;
  border-radius: 40px;
  margin-left: 25px;
  margin-right: 5px;
  object-fit: cover;
}

.leaderboard-user-name {
  margin-left: 10px;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #000000;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.leaderboard-medal {
  margin-right: 25px;
}
.leaderboard-index {
  min-width: 20px;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.leaderboard-user-score {
  font-family: "GothamXNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.transaction-space {
  background-color: white;
  min-height: calc(100vh - 70px);
  width: 100%;
  padding-top: 20px;
  height: 100%;
  overflow: scroll;
  padding-top: 110px; 
}
.transactions-title {
  font-family: "GothamXNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
  margin-left: 16px;
}

.transcation-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 40px;
}

.transaction-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
}

.transaction-date {
  font-family: "GothamXNarrow";
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #969696;
  margin-top: 4px;
  display: -webkit-flex;
  display: flex;
}

.transcation-description {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 16px;
}

.transaction-points-positiv {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: right;
  color: #007540;
}
.transaction-points-negativ {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
}

.transaction-descrtiption-text {
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.instantprice_pickedup{
  font-family: 'GothamNarrow';
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  background-color: #007540;
  padding: 2px;
  margin-left: 5px;
}
.transaction-error-page {
  width: 100%;
  height: calc(100vh - 250px);
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: white;
  z-index: 2;
}

.transaction-error-img {
  width: 70px;
  margin-bottom: 30px;
}
.transaction-error-title {
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #969696;
  margin-bottom: 40px;
}
.transaction-error-btn-container {
  padding-left: 60px;
  padding-right: 60px;
}

.shop-detail-container {
  background-color: white;
  width: 100%;
  padding-top: 70px;
  min-height: 100vh;
}

.shop-detail-container-notavailable {
  background-color: white;
  width: 100%;
  margin-top: 70px;
  min-height: 100vh;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.carousel {
  height: 300px;
}

.slide {
  background-color: transparent !important;
  width: 100%;
  height: 20% !important;
}

.shop-detail-badge {
  margin-left: 36px;
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-top: 13px;
}

.shop-detail-badge-text {
  object-fit: contain;
  font-family: "GothamXNarrow";
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.1px;
  text-align: center;
  color: #FFF;
  padding-left: 5px;
  padding-right: 5px;
}

.shop-detail-title {
  margin-top: 19px;
  font-family: "GothamXNarrow";
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: 0.2px;
  color: #1e1e1e;
  margin-left: 34px;
  margin-right: 34px;
}

.shop-detail-bubble {
  width: 60px;
  height: 60px;
  background-color: #ffd42e;
  border-radius: 30px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 19px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.control-dots {
  margin-bottom: 20px !important;
}

.dot {
  background-color: #b0b0b0 !important;
}

.ticket-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-right: 25;
  cursor: pointer;
}

.shop-detail-ticket-container {
  margin-left: 34px;
  margin-right: 34px;
  margin-top: 35px;
}

.shop-detail-text {
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000000;
  margin-right: 0px;
}

.shop-detail-text a {
  color: #007540;
  text-decoration: none;
}

.shop-detail-image {
  margin-right: 18px;
}

.shop-detail-ticket-detail {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 18px;
}

.shop-detail-ticket-detail img {
  object-fit: contain !important;
  width: 18px !important;
  height: 18px !important;
}

.shop-detail-ticket-description {
  margin-top: 35px;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 30px;
}

.shop-detail-ticket-description-title {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.shop-detail-ticket-description-text {
  margin-top: 8px;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #000000;
}

.ticket-bought-text {
  font-family: 'GothamNarrow';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #1a1a1a;
  padding-left: 15px;
  padding-right: 15px;
}

.shop-detail-title-image {
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.btn-basic-deactivated{
  font-family: 'GothamXNarrow';
  font-size: 14px;
  border-style: none;
  padding: 13px;
  border-radius: 4px;
  font-weight: bold;
  color: rgba(150,150,150,0.21);
  width: 100%;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0,0,0,0.3);
  }
.shop-detail-buy-container {
  background-color: white;
  width: 100%;
  height: 100%;
  padding-top: 1px;
  overflow: scroll;
  padding-top: 110px;
}
.shop-detail-buy-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 50px;
}

.shop-detail-buy-title {
  font-family: "GothamXNarrow";
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #007540;
}

.shop-detail-buy-subtitle {
  margin-top: 9px;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.shop-detail-buy-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 0px;
}
.shop-detail-buy-item-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.shop-detail-buy-item-title {
  margin-left: 10px;
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 15px;
}

.shop-detail-buy-item-subtitle {
  margin-left: 10px;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1a1a1a;
  max-height: 46px;
  overflow: hidden;
}

.shop-detail-buy-points {
  margin: 30px;
  margin-top:40px;
  margin-bottom: 55px;
}

.shop-detail-buy-points-title {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 15px;
}

.shop-detail-buy-points-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 5px;
}

.shop-detail-buy-points-subtitle {
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #282828;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.shop-detail-buy-points-subtitle-green {
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #007540;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.shop-detail-buy-points-points {
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: right;
  color: #282828;
}

.shop-detail-buy-points-btn-yellow {
  border-radius: 4px;
  background-color: #ffd42e;
  text-align: center;
  padding: 12px;
  margin: 12px;
  margin-top: 18px;
  margin-left: 30px;
  margin-right: 30px;
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: 0.2px;
  color: #1a1a1a;
  cursor: pointer;
}

.shop-detail-buy-points-btn-outlined {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  background-color: white;
  text-align: center;
  padding: 12px;
  margin: 12px;
  margin-left: 30px;
  margin-right: 30px;
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: 0.2px;
  color: #1a1a1a;
  cursor: pointer;
}

.shop-detail-buy-points-points-green {
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: 0.1px;
  text-align: right;
  color: #007540;
}

.title-header-white {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 50px;
}

.title-header-white-title {
  font-family: "GothamXNarrow";
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #007540;
}

.title-header-white-subtitle {
  margin-top: 9px;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.shop-detail-confirmation-container {
  background-color: white;
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding-top: 110px;
  padding-bottom: 40px;
}

.shop-detail-confirmation-code {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  margin-top: 37px;
}

.shop-detail-confirmation-code-text {
  padding: 14px;
  width: 40%;
  text-align: center;
  font-family: "GothamNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.1px;
  text-align: center;
  color: #1a1a1a;
  margin: 14px;
  border-color: #005e34;
  border-width: 2px;
  border-style: solid;
}

.shop-detail-confirmation-title {
  font-family: "GothamXNarrow";
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #007540;
  margin-top: 35px;
  margin-left: 18px;
  margin-right: 18px;
}

.shop-detail-confirmation-subtitle {
  font-family: "GothamNarrow";
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.1px;
  color: #1a1a1a;
  margin-left: 18px;
  margin-right: 18px;
  margin-top: 8px;
  margin-bottom: 10px;
}
.shop-detail-confirmation-points {
  position: absolute;
  top: 114px;
  right: 19px;
  background-color: #ffd42e;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}
.shop-detail-confirmation-points-text {
  font-family: "GothamXNarrow";
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.shop-detail-confirmation-cattitle {
  font-family: "GothamXNarrow";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 28px;
}

.shop-detail-confirmation-text-bold {
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: 0.1px;
  color: #1a1a1a;
  margin-left: 20px;
  margin-right: 20px;
}

.shop-detail-confirmation-text {
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: 0.1px;
  color: #1a1a1a;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 75px;
}

.barcode-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.quiz-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.question-container {
  padding: 15px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-flex-direction: column;
          flex-direction: column;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.question-answeres {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 75px;
}

.question-answere {
  border-radius: 5px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.9);
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00542e;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center;
}

.question-answere-right {
  margin: 7px;
  padding: 30px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #00b061;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00542e;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center;
  color: white;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  @include transition(opacity, 1s ease-out);
}

.question-answere-wrong {
  margin: 7px;
  padding: 30px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: red;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00542e;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.question-selected {
  margin: 7px;
  padding: 25px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.9);
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00542e;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 5px;
  border-color: #ffd42e;
}

.question-selected-wrong {
  margin: 7px;
  padding: 25px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.9);
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00542e;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 5px;
  border-color: #d0021b;
}

.question-pretitle {
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #ffffff;
  text-align: center;
}

.question-title {
  margin-top: 2px;
  font-family: "GothamXNarrow";
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;

  margin-bottom: 50px;
}

.question-text-right {
  font-family: "GothamXNarrow";
  font-size: 38px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.76;
  letter-spacing: normal;
  color: #ffffff;
  text-align: center;
  margin-bottom: 18px;
}

.question-explaination {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-align: center;
  margin-left: 22px;
  margin-right: 22px;
  margin-bottom: 50px;
}

.question-question-header-container {
  display: -webkit-flex;
  display: flex;
  height: 40vh;
  -webkit-justify-content: center;
          justify-content: center;
  /* -webkit-align-items: flex-end; */
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 70px;
}

.answere-container {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.answere-inner-container {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.answere-inner-container-selected {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border-color: #ffd42e;
  border-style: solid;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: relative;
}
.answere-inner-container-wrong {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border-color: #d0021b;
  border-style: solid;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: relative;
}
.answere-correct {
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  background-color: #00b061;
  opacity: 1;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.anwere-text {
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00542e;
}
.anwere-text-white {
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.question-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 80px;
  padding-top: 70px;
}
.question-header-title {
  font-family: "GothamXNarrow";
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-left: 16px;
  margin-right: 16px;
}

.question-header-subtitle {
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #ffffff;
}

.question-header-image {
  width: 60px;
  height: 60px;
}
.question-header-text {
  font-family: "GothamXNarrow";
  font-size: 38px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.76;
  letter-spacing: normal;
  color: #ffffff;
}

.question-header-answered {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.question-header-answered-subtext {
  margin-top: 20px;
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.points-plus {
  font-family: "GothamXNarrow";
  font-size: 23px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
}

.username-container {
  width: 100%;
  max-width: 625px;
  margin: auto;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.username-input-container{
  margin-top: 30px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.username-input {
  padding: 18px;
  width: 80%;
  border-radius: 2px;
  background-color: #005e34;
  border-color: #009150;
  border-width: 1px;
  text-align: center;

  opacity: 1;
  font-family: "GothamXNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.5; /* Firefox */
}
::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.5; /* Firefox */
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.5; /* Firefox */
}

.username-agb-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 80%;
  margin-top: 50px;
}
.username-agb-text {
  font-family: 'GothamNarrow';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #ffffff;
  margin-left: 7px;
}

.username-btn-container {
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 30px;
  margin-left: 24px;
  margin-right: 24px;
}

.codeinput-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 15px;
  width: calc(100% - 30px);
  max-width: 500px;
}

.codinput-header-done {
  font-family: "GothamNarrow";
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
}

.codinput-header-img {
  height: 20px;
}

.codinput-header-points {
  width: 30px;
  height: 30px;
}

.codeinput-center {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 50px;
  background-color: #007540;
  border-radius: 8px;
  padding-bottom: 50px;
}

.codeinput-center-title {
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 6px;
}

@media only screen and (max-width: 650px) {
  .codeinput-center {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-bottom: 90vh;
  }
}
.inputcomponent-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 5px;
  border-color: #009150;
  border-width: 3px;
  border-style: solid;
  background-color: #005e34;
  margin: 6px;
}

.inputcomponent-row-red {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 5px;
  border-color: #ff4d4d;
  border-width: 3px;
  border-style: solid;
  background-color: #005e34;
  margin: 6px;
}

.inputcomponent-row-green {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 5px;
  border-color: #00b061;
  border-width: 3px;
  border-style: solid;
  background-color: #005e34;
  margin: 6px;
}

.inputcomponent-btn {
  margin: 6px;
  margin-top: 20px;
  background-color: #ffd42e;
  border-radius: 5px;
  padding: 15;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.inputcomponent-btn-text {
  margin: 15px;
  font-family: "GothamXNarrow";
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0f0f0f;
}

.errormessage-container {
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  margin: 6px;
  padding: 7px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  margin-top: 30px;
  cursor: pointer;
}

.errormessage-img {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.errormessage-text {
  opacity: 0.9;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-left: 8px;
}

.errormessage-container-background {
  height: 100%;
  width: 100%;
  background-color: #000000;
  opacity: 0.2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 5px;
}

.error_out_of_range_text {
  margin-top: 18px;
  margin-bottom: 8px;
  font-family: "GothamNarrow";
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.input-component-oval {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
}

.input-component-oval-text {
  font-family: "GothamNarrow";
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
  width: 40px;
  height: 40px;
  background-color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 50%;
  margin-top: 20px;
}

.codeinputfield-container {
  height: 50px;
  width: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-color: #e6cb30;
  border-width: 3px;
  border-style: solid;
  border-radius: 6px;
  margin: -3px;
  position: relative;
}

.codeinputfield-container-inactiv {
  height: 50px;
  width: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.codeinputfield-container-filled {
  height: 50px;
  width: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.codeinputfield-text {
  font-family: "GothamXNarrow";
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.codeinputfield-text-inaktiv {
  font-family: "GothamXNarrow";
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  opacity: 0.4;
}

.codeinputfield-input {
  opacity: 0;
}

.codeinput-dot-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-width: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.codeinput-dot {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
}
.codeinputfield-input-inaktiv {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
}

.maps-boxed-container {
  padding: 6px 10px 6px 10px;
  position: absolute;
  bottom: 50px;
  z-index: 10;
}
.maps-boxed-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.6;
  border-radius: 5px;
  background-color: #000000;
  padding: 6 10 6 10;
}
.maps-boxed-text {
  opacity: 0.9;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.shop-list-container {
  width: 100%;
  min-height: 100%;
  margin-top: 110px;
}

.shop-card-item-container {
  width: calc(50% - 16px);
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 15px;
}
.codeinputdone-container {
  margin: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  height: 50vh;
  width: 100%;
  max-width: 500px;
}
.codeinputdone-text {
  font-family: "GothamXNarrow";
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 40px;
}

.codeinputdone-points {
  background-color: white;
  width: 70px;
  height: 70px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 35px;
}
.codeinputdone-points-text {
  font-family: "GothamXNarrow";
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #007540;
}
.codeinput-header{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.event-details-container {
    background-color: #fff;
    width: 100%;
    min-height: 100vh;
    position: relative;
}

.event-not-yet-started {
    font-family: GothamNarrow;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000000;
    font-weight: bold;
}

.event-details-price {
    width: 68px !important;
    height: 68px !important;
    position: absolute;
    top: 18px;
    right: 18px;
}

.event-details-container img {
    width: 100%;
    height: 300px;
    max-height: 70vw;
    object-fit: cover;
}

.event-details-price-img-container {
    width: 100%;
    position: relative;
    padding-top: 90%;
}

.event-details-price-img-container img {
    max-height: none !important;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.event-details-container-header-image {
    width: 100%;
    height: auto;
    max-height: 70vw;
    object-fit: cover;
}

.event-details-container-content {
    padding: 20px 30px;
    padding-top: 30px;
    padding-bottom: 100px;
}

.event-details-container-title {
    font-family: GothamXNarrow;
    font-size: 36px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.11;
    letter-spacing: 0.2px;
    color: #1e1e1e;
}

.event-detail-text {
    font-weight: bold;
}

.event-detail-text-bold {
    font-weight: bold;
}

.event-detail-text-color {
    color: #007540 !important;
}

.event-details-container-wrapper {
    margin-top: 37px;
    margin-bottom: 30px;
}

.event-details-container-description {
    font-family: GothamNarrow;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000000;
    padding-top: 9px;
    padding-bottom: 9px;
    margin-bottom: 24px;
}

.event-details-container-description span {
    font-family: GothamNarrow;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000000;
}

.mb-60 {
    margin-bottom: 60px;
}
.leaderboard-space {
  background-color: white;
  min-height: 100vh;
  width: 100%;
}

.leaderboard-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.personal-space {
  background-color: #007540;
  min-height: calc(100vh - 70px);
  width: 100%;
  padding-top: 110px;
  max-width: 500px;
}
.profile-title {
  margin-top: 28px;
  font-family: "GothamXNarrow";
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.personal-space-image-container {
  position: relative;
  margin-top: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.personal-space-image {
  width: 95px;
  height: 95px;
  object-fit: cover;
  background-color: #008a4c;
}

.personal-space-form {
  margin-top: 43px;
  width: 100%;
}

.personal-space-form-item {
  border-bottom: solid 0.5px #cbcbcb;
  margin-bottom: 29px;
}
.personal-space-form-item-edit {
  margin-bottom: 30px;
}
.personal-space-form-title {
  font-family: "GothamXNarrow";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
  margin-bottom: 2px;
}

.personal-space-form-input {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 22px;
  letter-spacing: normal;
  color: #000000;
  margin-top: 5px;
}

.personal-space-btn-edit {
  border-radius: 4px;
  background-color: #ffd42e;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 12px;
  margin-top: 48px;
  cursor: pointer;
}

.personal-space-btn-edit-text {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: 0.2px;
  color: #1a1a1a;
}

.personal-space-form-title {
  font-family: "GothamXNarrow";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
  margin-bottom: 2px;
}

.personal-space-form-input-edit {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  line-height: 22px;
  padding: 9px;
  padding-left: 10px;
  margin-top: 4px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #cbcbcb;
  width: 94%;
}

.personal-space-form-input-edit:focus {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  line-height: 22px;
  padding: 9px;
  padding-left: 10px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #007540;
  width: 94%;
  outline: none;
}
.personal-space-form-input-edit-error {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  line-height: 22px;
  padding: 9px;
  padding-left: 10px;
  margin-top: 4px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #ff4d4d;
  width: 94%;
}
.personal-space-form-input-edit-error:focus {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  line-height: 22px;
  padding: 9px;
  padding-left: 10px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #ff4d4d;
  width: 94%;
  outline: none;
}

.account-setup-error {
  margin-top: 8px;
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff4d4d;
}

.personal-space-btn-edit-disabled {
  opacity: 0.21;
  border-radius: 4px;
  background-color: #969696;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 12px;
  margin-top: 48px;
  cursor: pointer;
}

.personal-space-input-container {
  padding: 20px;
}

.register-control {
  background-color: #005e34 !important;
  border: 1px solid #009150 !important;
  border-radius: 5px !important;
  height: 50px !important;
  margin-top: 5px;
}

.register-placeholder {
  color: white;
}

.upload-container {
  position: absolute;
  width: 29px;
  right: 0;
  bottom: 0;
}

.personal-space-image-btn {
  width: 29px;
  cursor: pointer;
}

.personal-space-input {
  position: absolute;
  bottom: 0;
  width: 29px;
  height: 29px;
  opacity: 0;
}

.personal-space-input label {
  background-color: rgba(0, 0, 0, 0);
}

.agecheck-title {
    font-family: 'GothamXNarrow';
    font-size: 38px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.agecheck-subtitle {
    margin-top: 20px;
    opacity: 0.8;
    font-family: 'GothamNarrow';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}
.register-container {
    padding: 0px;
    padding-top: 110px;
}

.register-container-form {
    padding: 20px;
    min-width: calc(100% - 40px);
}

.register-agb {
    font-family: "GothamNarrow";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 35px;
}

.register-agb label {
    cursor: pointer;
}

.register-agb a {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    text-decoration: underline;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 35px;
}
.register-agb-center {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    text-decoration: underline;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 35px;
    text-align: center;
}

.rc-checkbox {
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    display: inline-block;
    position: relative;
    line-height: 1;
    vertical-align: middle;
    margin-right: 10px;
}

.rc-checkbox:hover .rc-checkbox-inner, .rc-checkbox-input:focus+.rc-checkbox-inner {
    border-color: #006638;
}

.rc-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    width: 14px;
    height: 14px;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    border-color: #d9d9d9;
    background-color: #ffffff;
    transition: border-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55), background-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.rc-checkbox-inner:after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: 4px;
    top: 1px;
    display: table;
    width: 5px;
    height: 8px;
    border: 2px solid #ffffff;
    border-top: 0;
    border-left: 0;
    content: ' ';
    -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
            animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-name: amCheckboxOut;
            animation-name: amCheckboxOut;
}

.rc-checkbox-input {
    position: absolute;
    left: 0;
    z-index: 9999;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.rc-checkbox-checked:hover .rc-checkbox-inner {
    border-color: #006638;
}

.rc-checkbox-checked .rc-checkbox-inner {
    border-color: #006638;
    background-color: #006638;
}

.rc-checkbox-checked .rc-checkbox-inner:after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    left: 4px;
    top: 1px;
    display: table;
    width: 5px;
    height: 8px;
    border: 2px solid #ffffff;
    border-top: 0;
    border-left: 0;
    content: ' ';
    -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
            animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-name: amCheckboxOut;
            animation-name: amCheckboxOut;
}

.rc-checkbox-disabled.rc-checkbox-checked:hover .rc-checkbox-inner {
    border-color: #d9d9d9;
}

.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner {
    background-color: #f3f3f3;
    border-color: #d9d9d9;
}

.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner:after {
    -webkit-animation-name: none;
            animation-name: none;
    border-color: #cccccc;
}

.rc-checkbox-disabled:hover .rc-checkbox-inner {
    border-color: #d9d9d9;
}

.rc-checkbox-disabled .rc-checkbox-inner {
    border-color: #d9d9d9;
    background-color: #f3f3f3;
}

.rc-checkbox-disabled .rc-checkbox-inner:after {
    -webkit-animation-name: none;
            animation-name: none;
    border-color: #f3f3f3;
}

.rc-checkbox-disabled .rc-checkbox-inner-input {
    cursor: default;
}

@-webkit-keyframes amCheckboxIn {
    0% {
        opacity: 0;
        -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        -webkit-transform: scale(0, 0) rotate(45deg);
                transform: scale(0, 0) rotate(45deg);
    }
    100% {
        opacity: 1;
        -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        -webkit-transform: scale(1, 1) rotate(45deg);
                transform: scale(1, 1) rotate(45deg);
    }
}

@keyframes amCheckboxIn {
    0% {
        opacity: 0;
        -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        -webkit-transform: scale(0, 0) rotate(45deg);
                transform: scale(0, 0) rotate(45deg);
    }
    100% {
        opacity: 1;
        -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        -webkit-transform: scale(1, 1) rotate(45deg);
                transform: scale(1, 1) rotate(45deg);
    }
}

@-webkit-keyframes amCheckboxOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes amCheckboxOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.primary-button {
    border-radius: 4px;
    background-color: #ffd42e;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 12px;
    cursor: pointer;
}
.primary-button:hover {
    border-radius: 4px;
    background-color: #F2F2F2;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 12px;
    cursor: pointer;
}

.primary-button-text {
    font-family: "GothamXNarrow";
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
    letter-spacing: 0.2px;
    color: #1a1a1a;
}

.headertitle {
    font-family: "GothamXNarrow";
    font-size: 26px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    line-height: 35px;
    margin-bottom: 7px;
    margin-top: 20px;
}

.headersubtitle {
    font-family: "GothamNarrow";
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.public-header {
    background-color: #008a4c;
    height: 100px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.public-header img {
    height: 40px;
}
.link {
    text-decoration: underline;
    cursor: pointer;
}
.faq {
  padding-top: 100px;
}

.faq-title {
  margin-top: 20px;
  padding: 20px;
  font-family: "GothamXNarrow";
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #ffffff;
}
.faq-subtitle {
  padding: 20px;
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: #ffffff;
}
.faq-question-title {
  font-family: "GothamXNarrow";
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 20px;
}

.textslide-seperator{
  border-top: 1px solid rgba(54, 52, 52, 0.4);
}

.link{
  color: #ffffff;
}
.textslide-container {
  padding: 10px;
}

.textslide-title {
  font-family: "GothamXNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
}
.textslide-text {
  padding-top: 20px;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 0px;
}
.textslide-text a {
  color: #ffffff;
}
.textslide-title-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}
.textslide-title-img-next {
  width: 14px;
  height: 14px;
  margin-right: 15px;
}

.textslide-down {
  background-color: #008a4c;
}

.transaction-space {
  background-color: white;
  min-height: calc(100vh - 70px);
  width: 100%;
  padding-top: 20px;
  height: 100%;
  overflow: scroll;
  padding-top: 110px; 
}
.transactions-title-container {
  margin-bottom: 18px;
  margin-top: 30px;
}
.transactions-title {
  font-family: "GothamXNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
  margin-left: 16px;
}

.transaction-space {
  background-color: white;
  min-height: calc(100vh - 70px);
  width: 100%;
  padding-top: 20px;
  height: 100%;
  padding-top: 110px; 
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;

  overflow: hidden;
}



.transaction-dropdown .Dropdown-control {
  border: 1px #fff solid;
}

.transaction-dropdown .Dropdown-placeholder {
  font-weight: bold;
}

.transactions-title-container {
  margin-bottom: 18px;
  margin-top: 30px;
}
.transaction-dropdown {
  width: calc(100% - 32px);
  padding: 16px;
}
.transaction-dropdown .Dropdown-root .is-open {
  border: 0px solid #007540 !important;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.transaction-dropdown .Dropdown-menu {
  border: 0px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}
.transaction-dropdown .Dropdown-root {
  border: 3px;
  font-weight: bold;
}
.transaction-dropdown .Dropdown-control {
  border: none;
  font-weight: bold;
  margin-left: 0px;
  padding-left: 0px;
}
.transaction-dropdown .Dropdown-control:hover {
  box-shadow: none;
  margin-left: 0px;
  padding-left: 0px;
}
.transaction-dropdown .Dropdown-arrow-wrapper .Dropdown-arrow {
  border-color: black transparent transparent;
}
.transaction-dropdown .Dropdown-control {
  border: none;
  font-weight: bold;
  width: 100%;
}
.transaction-dropdown .Dropdown-arrow-wrapper .Dropdown-arrow {
  border-color: none !important;
}

.transactions-title {
  font-family: "GothamXNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
  margin-left: 16px;
}

.faq {
  padding-top: 100px;
}

.faq-title {
  margin-top: 20px;
  padding: 20px;
  font-family: "GothamXNarrow";
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #ffffff;
}
.faq-subtitle {
  padding: 20px;
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: #ffffff;
}
.faq-question-title {
  font-family: "GothamXNarrow";
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 20px;
}

.textslide-seperator{
  border-top: 1px solid rgba(54, 52, 52, 0.4);
}

.shop-list-container {
  width: 100%;
  min-height: 100%;
  margin-top: 90px;
}

.shop-item-list-instantprice {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  background-color: rgb(0, 117, 64);
  margin-bottom: 20px;
}

.faq {
  padding-top: 100px;
}

.faq-title {
  margin-top: 20px;
  padding: 20px;
  font-family: "GothamXNarrow";
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #ffffff;
}
.faq-subtitle {
  padding: 20px;
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: #ffffff;
}
.faq-question-title {
  font-family: "GothamXNarrow";
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 20px;
}

.textslide-seperator{
  border-top: 1px solid rgba(54, 52, 52, 0.4);
}

