.field-container {
  position: relative;
  margin-top: 5px;
  width: 100%;

  .textinput-gray {
    border: 1px solid #009150;
    box-sizing: border-box;
    border-radius: 5px;
    letter-spacing: -0.02em;
    width: calc(100% - 32px);
    padding: 16px;
    margin-bottom: 5px;
    width: 100%;
    height: 50px;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #005e34;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    color: #ffffff;
  }
 

  input:focus {
    border: 1px solid #ffd42e;
    outline: none;
  }

  .placeholdeText-error {
    color: #eb5757;
  }

  .placeholdeText {
    color: rgba(255, 255, 255, 0.6);
  }

  .textinput-error {
    border: 1px solid #eb5757;
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 16px;
    letter-spacing: -0.02em;
    width: calc(100% - 32px);
    padding: 16px;
    margin-bottom: 5px;
    width: 100%;
  }

  label {
    display: inline-block;
    position: absolute;
    left: 15px;
    top: 19px;
    transition: all 150ms ease-in;
    color: #bdbdbd;
  }

  label.field-active {
    transform: translateY(-10px);
    font-size: 11px;
  }
  @media only screen and (max-width: 330px) {
    label.field-inactive {
      font-size: 12px;
    }
  }

  .field-group-active input {
    padding-top: 24px;
    padding-bottom: 8px;
  }

  .floating-label {
    -webkit-appearance: none !important;
  }
}
