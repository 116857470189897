.codeinputfield-container {
  height: 50px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #e6cb30;
  border-width: 3px;
  border-style: solid;
  border-radius: 6px;
  margin: -3px;
  position: relative;
}

.codeinputfield-container-inactiv {
  height: 50px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.codeinputfield-container-filled {
  height: 50px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.codeinputfield-text {
  font-family: "GothamXNarrow";
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.codeinputfield-text-inaktiv {
  font-family: "GothamXNarrow";
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  opacity: 0.4;
}

.codeinputfield-input {
  opacity: 0;
}

.codeinput-dot-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.codeinput-dot {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
}
.codeinputfield-input-inaktiv {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
}
