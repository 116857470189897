.maps-boxed-container {
  padding: 6px 10px 6px 10px;
  position: absolute;
  bottom: 50px;
  z-index: 10;
}
.maps-boxed-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.6;
  border-radius: 5px;
  background-color: #000000;
  padding: 6 10 6 10;
}
.maps-boxed-text {
  opacity: 0.9;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
