.modal-content {
  width: 90%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 100%;
}

.modal-tutorial-button {
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.modal-skip-button {
  margin-top: 30px;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.modal-close-button {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 14px;
  left: 14px;
  cursor: pointer;
}

.modal-title {
  font-family: "GothamXNarrow";
  font-size: 38px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.modal-desc-text {
  margin: 24px;
  text-align: center;
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

@media screen and (min-width: 600px) {
  .modal-content {
    max-width: 500px;
    margin: auto;
  }
}

.ReactModal__Overlay {
  z-index: 199;
}

.ReactModal__Content {
  border: none !important;
}

.modal-center {
  z-index: 99;
  position: relative;
}
.onboard-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}
.onboard-center-img {
  width: 80%;
  height: 80%;
}
.onboard-footer {
  margin-top: 60px;
  width: 100%;
  margin-bottom: 20px;
  z-index: 2;
}
.onboard-footer-btn {
  padding-left: 20px;
  padding-right: 20px;
}
