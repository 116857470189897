.content {
  background-color: #007540;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.content-top {
  padding: 20px;
  color: white;
  font-size: 24px;
  position: fixed;
  top: 0;
  height: 50px;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  background-color: #007540;
}

.link-u {
  cursor: pointer;
}

.content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.how-it-works-container {
  margin-top: 70px;
}

.login-image-container .fake-spacer {
  width: 100vw;
  height: 100vh;
}

.login-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 50px;
  background-color: #008a4c;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 99;
}

.content-center .title {
  font-family: "GothamXNarrow";
  font-size: 34px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.content-center .subtitle {
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.login-image-container {
  position: relative;
}

.logo-container {
  width: 100%;
  object-fit: contain;
}

.logo-container-desktop {
  width: 100%;
}

.login-body-desktop {
  background-color: #1f6131;
  width: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
}

.logo-container-desktop img {
  width: 100%;
  height: calc(100vh - 70px);
  object-fit: cover;
}

.logo {
  object-fit: cover;
  width: 100%;
}

.btn-facebooklogin {
  width: 100%;
  background-color: #3c5a99;
  color: white;
  font-size: 20px;
  border-style: none;
  padding: 15px;
  margin-bottom: 12px;
  border-radius: 4px;
  font-weight: bold;
  font-family: "GothamXNarrow";
  cursor: pointer;
}

.btn-mobileemail {
  width: 100%;
  background-color: white;
  color: black;
  font-size: 20px;
  border-style: none;
  padding: 13px;
  margin-bottom: 12px;
  border-radius: 4px;
  font-weight: bold;
  font-family: "GothamXNarrow";
  cursor: pointer;
}

.content-agb {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 52px;
  margin-top: 60px;
}

.content-agb-element {
  margin-right: 15px;
  margin-bottom: 15px;
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
}

iframe {
  background-color: #fff;
}

.btn-emaillogin {
  font-family: "GothamNarrow";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
}

.btn-emaillogin a {
  font-family: "GothamNarrow";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.login-body {
  z-index: 1;
  height: calc(100vh - 120px);
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: absolute;
  bottom: -20px;
  margin-top: 80px;
}

.title {
  font-family: "GothamXNarrow";
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.login-body-desktop .title {
  margin-bottom: 14px;
}

.subtitle {
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.detail-title {
  font-family: "GothamXNarrow";
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 100px;
}

.bottom-btn-container {
  border-radius: 3px;
  background-color: #006638;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
}
.bottom-btn-container-title {
  margin-top: 30px;
  margin-bottom: 40px;
  font-family: "GothamXNarrow";
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.link-u {
  text-decoration: underline;
}

.main-login-page {
  padding-top: 70px;

  .header {
    background-color: #008a4c;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header img {
    height: 26px;
    width: auto;
  }

  .info-block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 70px);
  }

  .info-block .info-block-image {
    display: block;
    height: 100%;
    width: calc(100vw - 480px);
    object-fit: cover;
    background-position: center top;
    flex-grow: 1;
  }

  .info-block-info {
    width: 480px;
    background-color: #1f6131;
    padding-left: 60px;
    padding-right: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .text-header {
    font-family: GothamXNarrow;
    font-size: 42px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .text-body {
    font-family: GothamNarrow;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .stoerer {
    width: 198px;
    height: 198px;
    margin-top: 67px;
    margin-bottom: 40px;
  }

  h1 {
    font-family: "GothamXNarrow";
    font-size: 42px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    max-width: 625px;
  }

  .teaser {
    opacity: 0.8;
    font-family: "GothamNarrow";
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-top: 23px;
    max-width: 625px;
  }

  .sogehts {
    font-family: "GothamXNarrow";
    font-size: 26px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-top: 90px;
    margin-bottom: 60px;
  }

  .item-container {
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    max-width: 730px;
  }

  .item-container-image {
    width: 290px;
    height: auto;
  }

  .item-container-info-title {
    font-family: "GothamXNarrow";
    font-size: 42px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .item-container-info-text {
    opacity: 0.8;
    font-family: "GothamNarrow";
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    color: #ffffff;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .ml-15 {
    margin-left: 44px;
  }

  .mr-15 {
    margin-right: 44px;
  }

  .mobile {
    display: none !important;
  }

  .desktop {
    display: block !important;
  }

  @media only screen and (max-width: 650px) {
    .info-block {
      flex-direction: column;
      width: 100%;
      position: relative;
    }

    .info-block-info {
      position: absolute;
      bottom: 30px;
      left: 0px;
      right: 0px;
      background-color: transparent;
      width: calc(100% - 40px);
      padding-left: 20px;
      padding-right: 20px;
      justify-content: flex-end;
    }

    .info-block .info-block-image {
      width: 100%;
    }

    .mobile {
      display: block !important;
    }

    .desktop {
      display: none !important;
    }

    .ml-15 {
      margin-left: 0px;
    }

    .mr-15 {
      margin-right: 0px;
    }

    .item-container {
      flex-direction: column;
    }

    .item-container-reverted-mobile {
      flex-direction: column-reverse;
    }

    .item-container-image {
      margin-top: 44px;
    }

    .item-container-info {
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;
      width: calc(100% - 60px);
    }

    .teaser {
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;
      width: calc(100% - 60px);
    }

    h1 {
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;
    }

    .item-container-image {
      width: 90%;
      height: auto;
    }
  }
}

.footer-links {
  margin-top: 100px;
  margin-bottom: 50px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.footer-links .link {
  text-decoration: none;
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0px 20px;
}

@media only screen and (max-width: 650px) {
  .footer-links {
    flex-direction: column;
    padding-bottom: 60px;
  }

  .footer-links .link {
    padding-bottom: 20px;
    display: none;
  }
  
  .footer-links-always .link {
    display: block;
  }  
}