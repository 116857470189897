.codeinput-header {
  display: flex;
  flex-direction: row;
  padding: 15px;
  width: calc(100% - 30px);
  max-width: 500px;
}

.codinput-header-done {
  font-family: "GothamNarrow";
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
}

.codinput-header-img {
  height: 20px;
}

.codinput-header-points {
  width: 30px;
  height: 30px;
}

.codeinput-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  background-color: #007540;
  border-radius: 8px;
  padding-bottom: 50px;
}

.codeinput-center-title {
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 6px;
}

@media only screen and (max-width: 650px) {
  .codeinput-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 90vh;
  }
}