.transaction-space {
  background-color: white;
  min-height: calc(100vh - 70px);
  width: 100%;
  padding-top: 20px;
  height: 100%;
  overflow: scroll;
  padding-top: 110px; 
}
.transactions-title-container {
  margin-bottom: 18px;
  margin-top: 30px;
}
.transactions-title {
  font-family: "GothamXNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
  margin-left: 16px;
}
