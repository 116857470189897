.pagetitleheader-container-green {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 34px;
  padding-top: 35px;
  padding-bottom: 28px;
}

.pagetitleheader-title-green {
  text-align: center;
  font-family: 'GothamXNarrow';
  font-size: 38px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.pagetitleheader-subtitle-green {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 5px;
  font-family: 'GothamNarrow';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  opacity: 0.7;
}
