.btn-basic {
    font-size: 18px;
    border-style: none;
    padding: 13px;
    border-radius: 4px;
    font-weight: bold;
    font-family: 'GothamXNarrow';
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
    letter-spacing: 0.2px;
    color: #000000;
    width: 100%;
    cursor: pointer;
    text-transform: uppercase;
  }

  .btn-basic:hover {
    font-size: 18px;
    border-style: none;
    padding: 13px;
    border-radius: 4px;
    font-weight: bold;
    font-family: 'GothamXNarrow';
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
    letter-spacing: 0.2px;
    color: #000000;
    width: 100%;
    cursor: pointer;
    text-transform: uppercase;
}