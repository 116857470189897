.card-container {
  width: 100%;
  height: 170px;
  background-color: white;
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;
}
.card-header {
  display: flex;
  position: absolute;
  top: 16px;
  left: 16px;
}
.card-header-title {
  font-family: "GothamXNarrow";
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffd42e;
  margin-left: 6px;
}
.card-body {
  padding: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  padding-bottom: 30px;
}
.card-body-text {
  font-family: "GothamXNarrow";
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #ffffff;
}
.card-body-image {
  height: 100%;
  position: absolute;
  right: 7px;
  top: 0;
  bottom: 10px;
}

.card-body-image .image {
  object-fit: contain;
}
