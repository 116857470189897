.cardhuge-container {
  width: 100%;
  height: 440px;
  margin-bottom: 10px;
}

.cardhuge-inner-container {
  border-radius: 5px;
  background-color: #004f2b;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.cardhuge-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.cardHuge-text-container {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
}

.cardhuge-title {
  font-family: "GothamXNarrow";
  font-size: 48px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #ffd42e;
}
.cardhuge-subtitle {
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 12px;
  margin-top: 10px;
}
