.template-top {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
}

.template-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.template-title-container {
}
.template-title {
  font-family: "GothamXNarrow";
  font-size: 38px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.template-subtitle {
  margin-top: 17px;
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.template-btn-container {
  margin-top: 10px;
  width: 100%;
  padding-left: 34px;
  padding-right: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 40px;
  max-width: 500px;
}
.templatetutorial-cancle {
  padding: 10px;
  padding-bottom: 0px;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
