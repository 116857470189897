.codeinputdone-container {
  margin: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 50vh;
  width: 100%;
  max-width: 500px;
}
.codeinputdone-text {
  font-family: "GothamXNarrow";
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 40px;
}

.codeinputdone-points {
  background-color: white;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
}
.codeinputdone-points-text {
  font-family: "GothamXNarrow";
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #007540;
}
.codeinput-header{
  display: flex;
  justify-content: space-between;
}
