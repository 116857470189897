.agecheck-title {
    font-family: 'GothamXNarrow';
    font-size: 38px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.agecheck-subtitle {
    margin-top: 20px;
    opacity: 0.8;
    font-family: 'GothamNarrow';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}