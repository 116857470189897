.shop-card-full-with-item {
  width: 100%;
  border-radius: 5px;
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  padding-bottom: 44%;
}

.shop-card-full-with-item:hover {
  width: 100%;
  border-radius: 5px;
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  padding-bottom: 44%;
}

.shop-card-full-with-item--gradient {
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,0.9) 40%, rgba(255,255,255,0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.shop-card-full-with-item-title-container {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 20px;
}

.shop-card-full-with-item-title {
  font-family: "GothamNarrow";
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: black;
  margin-top: 10px;
}

.shop-card-full-with-item-subtitle {
  background-color: #ffd42e;
  padding: 5px;
  display: inline;
  font-family: "GothamNarrow";
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #000000;
}
.shop-card-item-full-with-top-right {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  width: 50px;
  height: 50px;
  background-color: #fbd42e;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 24px;
  font-family: "GothamXNarrow";
  text-align: center;
}

.shop-card-full-width-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: white;
  object-fit: cover;
}

.shop-card-full-width-background:hover {
  box-shadow: 0px 0px 3px 3px rgba(0,0,0,0.15);
}

.shop-card-item:hover {
  box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.25);
}