.shop-list-container {
  width: 100%;
  min-height: 100%;
  margin-top: 110px;
}

.shop-card-item-container {
  width: calc(50% - 16px);
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 15px;
}