.shop-detail-buy-container {
  background-color: white;
  width: 100%;
  height: 100%;
  padding-top: 1px;
  overflow: scroll;
  padding-top: 110px;
}
.shop-detail-buy-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px;
}

.shop-detail-buy-title {
  font-family: "GothamXNarrow";
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #007540;
}

.shop-detail-buy-subtitle {
  margin-top: 9px;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.shop-detail-buy-item {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 0px;
}
.shop-detail-buy-item-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.shop-detail-buy-item-title {
  margin-left: 10px;
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 15px;
}

.shop-detail-buy-item-subtitle {
  margin-left: 10px;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1a1a1a;
  max-height: 46px;
  overflow: hidden;
}

.shop-detail-buy-points {
  margin: 30px;
  margin-top:40px;
  margin-bottom: 55px;
}

.shop-detail-buy-points-title {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 15px;
}

.shop-detail-buy-points-item {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.shop-detail-buy-points-subtitle {
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #282828;
  flex-grow: 1;
}

.shop-detail-buy-points-subtitle-green {
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #007540;
  flex-grow: 1;
}

.shop-detail-buy-points-points {
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: right;
  color: #282828;
}

.shop-detail-buy-points-btn-yellow {
  border-radius: 4px;
  background-color: #ffd42e;
  text-align: center;
  padding: 12px;
  margin: 12px;
  margin-top: 18px;
  margin-left: 30px;
  margin-right: 30px;
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: 0.2px;
  color: #1a1a1a;
  cursor: pointer;
}

.shop-detail-buy-points-btn-outlined {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  background-color: white;
  text-align: center;
  padding: 12px;
  margin: 12px;
  margin-left: 30px;
  margin-right: 30px;
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: 0.2px;
  color: #1a1a1a;
  cursor: pointer;
}

.shop-detail-buy-points-points-green {
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: 0.1px;
  text-align: right;
  color: #007540;
}
