.shop-detail-container {
  background-color: white;
  width: 100%;
  padding-top: 70px;
  min-height: 100vh;
}

.shop-detail-container-notavailable {
  background-color: white;
  width: 100%;
  margin-top: 70px;
  min-height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel {
  height: 300px;
}

.slide {
  background-color: transparent !important;
  width: 100%;
  height: 20% !important;
}

.shop-detail-badge {
  margin-left: 36px;
  display: inline-flex;
  margin-top: 13px;
}

.shop-detail-badge-text {
  object-fit: contain;
  font-family: "GothamXNarrow";
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.1px;
  text-align: center;
  color: #FFF;
  padding-left: 5px;
  padding-right: 5px;
}

.shop-detail-title {
  margin-top: 19px;
  font-family: "GothamXNarrow";
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: 0.2px;
  color: #1e1e1e;
  margin-left: 34px;
  margin-right: 34px;
}

.shop-detail-bubble {
  width: 60px;
  height: 60px;
  background-color: #ffd42e;
  border-radius: 30px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.control-dots {
  margin-bottom: 20px !important;
}

.dot {
  background-color: #b0b0b0 !important;
}

.ticket-info {
  display: flex;
  flex-direction: row;
  margin-right: 25;
  cursor: pointer;
}

.shop-detail-ticket-container {
  margin-left: 34px;
  margin-right: 34px;
  margin-top: 35px;
}

.shop-detail-text {
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000000;
  margin-right: 0px;
}

.shop-detail-text a {
  color: #007540;
  text-decoration: none;
}

.shop-detail-image {
  margin-right: 18px;
}

.shop-detail-ticket-detail {
  display: flex;
  flex-direction: row;
  margin-top: 18px;
}

.shop-detail-ticket-detail img {
  object-fit: contain !important;
  width: 18px !important;
  height: 18px !important;
}

.shop-detail-ticket-description {
  margin-top: 35px;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 30px;
}

.shop-detail-ticket-description-title {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.shop-detail-ticket-description-text {
  margin-top: 8px;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #000000;
}

.ticket-bought-text {
  font-family: 'GothamNarrow';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #1a1a1a;
  padding-left: 15px;
  padding-right: 15px;
}

.shop-detail-title-image {
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}