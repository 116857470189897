.register-container {
    padding: 0px;
    padding-top: 110px;
}

.register-container-form {
    padding: 20px;
    min-width: calc(100% - 40px);
}

.register-agb {
    font-family: "GothamNarrow";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 35px;
}

.register-agb label {
    cursor: pointer;
}

.register-agb a {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    text-decoration: underline;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 35px;
}
.register-agb-center {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #ffffff;
    text-decoration: underline;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 35px;
    text-align: center;
}

.rc-checkbox {
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    display: inline-block;
    position: relative;
    line-height: 1;
    vertical-align: middle;
    margin-right: 10px;
}

.rc-checkbox:hover .rc-checkbox-inner, .rc-checkbox-input:focus+.rc-checkbox-inner {
    border-color: #006638;
}

.rc-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    width: 14px;
    height: 14px;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    border-color: #d9d9d9;
    background-color: #ffffff;
    transition: border-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55), background-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.rc-checkbox-inner:after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: 4px;
    top: 1px;
    display: table;
    width: 5px;
    height: 8px;
    border: 2px solid #ffffff;
    border-top: 0;
    border-left: 0;
    content: ' ';
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    animation-duration: 0.3s;
    animation-name: amCheckboxOut;
}

.rc-checkbox-input {
    position: absolute;
    left: 0;
    z-index: 9999;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.rc-checkbox-checked:hover .rc-checkbox-inner {
    border-color: #006638;
}

.rc-checkbox-checked .rc-checkbox-inner {
    border-color: #006638;
    background-color: #006638;
}

.rc-checkbox-checked .rc-checkbox-inner:after {
    transform: rotate(45deg);
    position: absolute;
    left: 4px;
    top: 1px;
    display: table;
    width: 5px;
    height: 8px;
    border: 2px solid #ffffff;
    border-top: 0;
    border-left: 0;
    content: ' ';
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    animation-duration: 0.3s;
    animation-name: amCheckboxOut;
}

.rc-checkbox-disabled.rc-checkbox-checked:hover .rc-checkbox-inner {
    border-color: #d9d9d9;
}

.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner {
    background-color: #f3f3f3;
    border-color: #d9d9d9;
}

.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner:after {
    animation-name: none;
    border-color: #cccccc;
}

.rc-checkbox-disabled:hover .rc-checkbox-inner {
    border-color: #d9d9d9;
}

.rc-checkbox-disabled .rc-checkbox-inner {
    border-color: #d9d9d9;
    background-color: #f3f3f3;
}

.rc-checkbox-disabled .rc-checkbox-inner:after {
    animation-name: none;
    border-color: #f3f3f3;
}

.rc-checkbox-disabled .rc-checkbox-inner-input {
    cursor: default;
}

@keyframes amCheckboxIn {
    0% {
        opacity: 0;
        transform-origin: 50% 50%;
        transform: scale(0, 0) rotate(45deg);
    }
    100% {
        opacity: 1;
        transform-origin: 50% 50%;
        transform: scale(1, 1) rotate(45deg);
    }
}

@keyframes amCheckboxOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.primary-button {
    border-radius: 4px;
    background-color: #ffd42e;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    cursor: pointer;
}
.primary-button:hover {
    border-radius: 4px;
    background-color: #F2F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    cursor: pointer;
}

.primary-button-text {
    font-family: "GothamXNarrow";
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
    letter-spacing: 0.2px;
    color: #1a1a1a;
}

.headertitle {
    font-family: "GothamXNarrow";
    font-size: 26px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    line-height: 35px;
    margin-bottom: 7px;
    margin-top: 20px;
}

.headersubtitle {
    font-family: "GothamNarrow";
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.public-header {
    background-color: #008a4c;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.public-header img {
    height: 40px;
}
.link {
    text-decoration: underline;
    cursor: pointer;
}