.navbar-container {
  background-color: rgba(204, 221, 213, 0.85);
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}


.navbar-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 20%;
  justify-content: flex-start;
}
.navbar-item-container-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 20%;
  min-height: 26px;
  cursor: pointer;
  margin-top: 5px;
}
.navbar-subtitle-aktiv {
  font-family: "GothamXNarrow";
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00542e;
  cursor: pointer;
}
.navbar-subtitle {
  font-family: "GothamXNarrow";
  font-size: 11px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 84, 46, 0.6);
  opacity: 60%;
  text-align: center;
  cursor: pointer;
}
.navbar-icon {
  height: 20px;
}

.navbar-points {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00b061;
  border-radius: 22px;
  position: absolute;
  top: -14px;
}

.main-navbar {
  position: fixed;
  bottom: -1px;
  left: 0px;
  right: 0px;
  height: 56px;
  background-color: rgb(199,219,209);
  display: flex;
  justify-content: center;
}