.question-container {
  padding: 15px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.question-answeres {
  display: flex;
  width: 100%;
  height: 75px;
}

.question-answere {
  border-radius: 5px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.9);
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00542e;
  flex-grow: 1;
  text-align: center;
}

.question-answere-right {
  margin: 7px;
  padding: 30px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #00b061;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00542e;
  flex-grow: 1;
  text-align: center;
  color: white;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  @include transition(opacity, 1s ease-out);
}

.question-answere-wrong {
  margin: 7px;
  padding: 30px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: red;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00542e;
  flex-grow: 1;
  text-align: center;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.question-selected {
  margin: 7px;
  padding: 25px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.9);
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00542e;
  flex-grow: 1;
  text-align: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 5px;
  border-color: #ffd42e;
}

.question-selected-wrong {
  margin: 7px;
  padding: 25px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.9);
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00542e;
  flex-grow: 1;
  text-align: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 5px;
  border-color: #d0021b;
}

.question-pretitle {
  opacity: 0.7;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #ffffff;
  text-align: center;
}

.question-title {
  margin-top: 2px;
  font-family: "GothamXNarrow";
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;

  margin-bottom: 50px;
}

.question-text-right {
  font-family: "GothamXNarrow";
  font-size: 38px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.76;
  letter-spacing: normal;
  color: #ffffff;
  text-align: center;
  margin-bottom: 18px;
}

.question-explaination {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-align: center;
  margin-left: 22px;
  margin-right: 22px;
  margin-bottom: 50px;
}

.question-question-header-container {
  display: flex;
  height: 40vh;
  justify-content: center;
  /* -webkit-align-items: flex-end; */
  align-items: center;
  flex-direction: column;
  padding-top: 70px;
}
