.shop-list-container {
  width: 100%;
  min-height: 100%;
  margin-top: 90px;
}

.shop-item-list-instantprice {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  background-color: rgb(0, 117, 64);
  margin-bottom: 20px;
}
