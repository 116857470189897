.personal-space {
  background-color: #007540;
  min-height: calc(100vh - 70px);
  width: 100%;
  padding-top: 110px;
  max-width: 500px;
}
.profile-title {
  margin-top: 28px;
  font-family: "GothamXNarrow";
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.personal-space-image-container {
  position: relative;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.personal-space-image {
  width: 95px;
  height: 95px;
  object-fit: cover;
  background-color: #008a4c;
}

.personal-space-form {
  margin-top: 43px;
  width: 100%;
}

.personal-space-form-item {
  border-bottom: solid 0.5px #cbcbcb;
  margin-bottom: 29px;
}
.personal-space-form-item-edit {
  margin-bottom: 30px;
}
.personal-space-form-title {
  font-family: "GothamXNarrow";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
  margin-bottom: 2px;
}

.personal-space-form-input {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 22px;
  letter-spacing: normal;
  color: #000000;
  margin-top: 5px;
}

.personal-space-btn-edit {
  border-radius: 4px;
  background-color: #ffd42e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin-top: 48px;
  cursor: pointer;
}

.personal-space-btn-edit-text {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: 0.2px;
  color: #1a1a1a;
}

.personal-space-form-title {
  font-family: "GothamXNarrow";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007540;
  margin-bottom: 2px;
}

.personal-space-form-input-edit {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  line-height: 22px;
  padding: 9px;
  padding-left: 10px;
  margin-top: 4px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #cbcbcb;
  width: 94%;
}

.personal-space-form-input-edit:focus {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  line-height: 22px;
  padding: 9px;
  padding-left: 10px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #007540;
  width: 94%;
  outline: none;
}
.personal-space-form-input-edit-error {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  line-height: 22px;
  padding: 9px;
  padding-left: 10px;
  margin-top: 4px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #ff4d4d;
  width: 94%;
}
.personal-space-form-input-edit-error:focus {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  line-height: 22px;
  padding: 9px;
  padding-left: 10px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #ff4d4d;
  width: 94%;
  outline: none;
}

.account-setup-error {
  margin-top: 8px;
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff4d4d;
}

.personal-space-btn-edit-disabled {
  opacity: 0.21;
  border-radius: 4px;
  background-color: #969696;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin-top: 48px;
  cursor: pointer;
}

.personal-space-input-container {
  padding: 20px;
}

.register-control {
  background-color: #005e34 !important;
  border: 1px solid #009150 !important;
  border-radius: 5px !important;
  height: 50px !important;
  margin-top: 5px;
}

.register-placeholder {
  color: white;
}

.upload-container {
  position: absolute;
  width: 29px;
  right: 0;
  bottom: 0;
}

.personal-space-image-btn {
  width: 29px;
  cursor: pointer;
}

.personal-space-input {
  position: absolute;
  bottom: 0;
  width: 29px;
  height: 29px;
  opacity: 0;
}

.personal-space-input label {
  background-color: rgba(0, 0, 0, 0);
}
